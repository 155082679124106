<template>
    <div
        class="dark:bg-gray-800 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    >
        <div class="max-w-md w-full space-y-8" v-if="loaded">
            <form class="mt-8 space-y-6" @submit.prevent="login" autocomplete="new-password">
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            v-model="email"
                            required
                            autocomplete="chrome-off"
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                            placeholder="Email address"
                        />
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            v-model="password"
                            required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                            placeholder="Password"
                        />
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        @click.prevent="login"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <!-- Heroicon name: lock-closed -->
                            <svg
                                class="h-5 w-5 text-teal-500 group-hover:text-teal-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
        <Loader v-else />
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import Loader from '@/components/Loader'

import errorHandler from '@/helpers/errorHandler'

export default {
    components: {
        Loader,
    },

    setup() {
        const router = useRouter()
        const state = reactive({
            email: '',
            password: '',
            loaded: false,
        })
        const login = async () => {
            try {
                await firebase.auth().signInWithEmailAndPassword(state.email, state.password)
                router.push({ name: 'Dashboard' })
            } catch (error) {
                state.password = ''
                errorHandler(error)
            }
        }

        const observeAuth = async () => {
            try {
                await firebase.auth().onAuthStateChanged(user => {
                    if (user) {
                        router.push({ name: 'Dashboard' })
                    } else {
                        state.loaded = true
                    }
                })
            } catch (error) {
                errorHandler(error)
            }
        }

        observeAuth()

        return { ...toRefs(state), login }
    },
}
</script>

<style lang="scss" scoped></style>
